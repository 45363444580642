import React, { useState } from "react";
import Questions from "../../Components/Questions";
import {
  SectionFaq,
  Gradient,
  FaqContainer,
  ContactButton,
  SearchContainer,
} from "./style";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SearchBar from "../../Components/SearchBar";
import SearchResultList from "../../Components/SearchResultsList";
import { Typography } from "@mui/material";
import Layout from "../../Components/Layout";

const Faq: React.FC = () => {
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [activeQuestionId, setActiveQuestionId] = useState<number | null>(null);

  const handleResultItemClick = (id: number) => {
    setActiveQuestionId(id);
  };

  return (
    <>
      <Layout>
        <SectionFaq>
          <Typography variant="h1">FAQ</Typography>
          <Gradient>
            <Typography variant="h2" component="h1" gutterBottom>
              Bem-vindo ao FAQ!
            </Typography>
            <Typography variant="h4" component="h2" gutterBottom>
              Qual a sua dúvida?
            </Typography>
            <SearchContainer>
              <SearchBar setResults={setSearchResults} />
              {searchResults.length > 0 && (
                <SearchResultList
                  onItemClick={handleResultItemClick}
                  results={searchResults}
                />
              )}
            </SearchContainer>
          </Gradient>
          <Questions
            highlightedId={activeQuestionId}
            setHighlightedId={setActiveQuestionId}
          />
          <FaqContainer>
            <Typography variant="h2" component="h1" gutterBottom>
              Não achou sua dúvida?
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              Entre em contato com nosso Whatsapp
            </Typography>
            <ContactButton href="https://api.whatsapp.com/send?phone=5585996500038">
              Entre em contato
              <WhatsAppIcon />
            </ContactButton>
          </FaqContainer>
        </SectionFaq>
      </Layout>
    </>
  );
};

export default Faq;
