import React, { useState, useEffect } from 'react';
import { SectionDocuments, ButtonView } from './style';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from '../../Services/config'
import DownloadIcon from '@mui/icons-material/Download';
import { Typography } from "@mui/material";

interface Document {
  id: number;
  title: string;
  description: string;
  file: string;
}

function Documents() {
  const [documents, setDocuments] = useState<Document[]>([]);

  const fetchDocuments = () => {
    api.get<Document[]>('/api/processos/')
      .then(response => {
        setDocuments(response.data);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  };

  useEffect(() => {
    fetchDocuments();

    const interval = setInterval(() => {
      fetchDocuments();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <SectionDocuments>
      <Typography variant="h2">Documentos</Typography>
      <div>
        {documents.map((document) => (
          <Accordion
            key={document.id}
            style={{ backgroundColor: "#F4F4F4", marginBottom: "20px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {document.title}
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={{ __html: document.description }} />
              <ButtonView href={document.file} target="_blank">
                <DownloadIcon style={{ marginRight: "5px" }} /> Visualizar
                arquivo
              </ButtonView>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </SectionDocuments>
  );
}

export default Documents;
