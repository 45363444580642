import styled from "styled-components";

export const WelcomeSection = styled.section`
height: 100%;
width: 100%;
background-color: #00000096;
position: fixed;
z-index: 999999999999999999;
display: flex;
justify-content: center;
align-items: center;
padding: 8px;


@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.modal-content {
  height: 90vh;
  width: 600px;
  background-color: #fff;
  z-index: 9999999999999999999999999999999;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 15px;
  animation: floatAnimation 3s ease infinite; 


  h2 {
    font-size: 30px;
  }

  @media (max-width: 768px){
    h2{
      margin-top: 20px;
    }
  }

  p {
    font-size: 16px;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  button {
    background-color: #00cb43;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    transition: all.3s;
  }

  button:hover {
    transform: scale(0.9);
  }
}


@media (max-width: 1020px){
    .modal-content{
        height: auto;
        width: 100%;
       
    }
  }
`;