import React from 'react';
import { WelcomeSection } from './style';
import WelcomeImg from '../../Assets/welcome.png';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

interface WelcomeModalProps {
  onClose: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ onClose }) => {
  const { width, height } = useWindowSize();

  return (
    <WelcomeSection>
      <Confetti width={width} height={height} />
      <div className="msg-contet">
        <div className="modal-content">
          <h2>Bem-vindo ao portal Tecler!</h2>
          <p>O seu novo meio de se comunicar com a Tecla T</p>
          <img src={WelcomeImg} alt="Welcome" />
          <button onClick={onClose}>Conhecer o Mundo Tecler</button>
        </div>
      </div>
    </WelcomeSection>
  );
};

export default WelcomeModal;
