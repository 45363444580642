import React from "react";
import { ResponsiveContainer } from "./style"; // Importe o componente estilizado
import Header from "../Header";
import Footer from "../Footer";
import SupportButton from "../Support";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <SupportButton />
      <Header />
      <ResponsiveContainer maxWidth="xl" disableGutters>
        {children}
      </ResponsiveContainer>
      <Footer />
    </>
  );
};

export default Layout;
