import React, { useState, useEffect } from "react";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { AvatarContainer } from "./style";
import { useNavigate } from "react-router-dom";

// Tipagem das propriedades do componente Profile
interface ProfileProps {
  children?: React.ReactNode;
}

const Profile: React.FC<ProfileProps> = ({ children }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLElement>
      | Event,
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    if (
      reason === "backdropClick" ||
      (event &&
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement))
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const data = JSON.parse(localStorage.getItem("data") || "{}");
  console.log(data);

  if (!data || !data.name || !data.picture) {
    navigate("/login");
    return null;
  }

  const fullName = data.name;
  const names = fullName.split(" ");
  const firstName = names[0];
  const lastName = names[names.length - 1];

  return (
    <AvatarContainer ref={anchorRef} onClick={handleToggle}>
      <span>{`${firstName} ${lastName}`}</span>
      <div>
        <Avatar
          alt="Profile Picture"
          src={data.picture}
          style={{ cursor: "pointer" }}
        />
        <Menu
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleClose}
          onKeyDown={handleListKeyDown}
        >
          <MenuItem
            onClick={() => {
              handleClose(new MouseEvent("click"));
              navigate("/profile");
            }}
          >
            Meu perfil
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.removeItem("data");
              handleClose(new MouseEvent("click"));
              navigate("/login");
            }}
          >
            Fazer logout
          </MenuItem>
        </Menu>
      </div>
    </AvatarContainer>
  );
};

export default Profile;
