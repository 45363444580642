import styled from "styled-components";

export const ResultList = styled.a`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  max-height: 300px;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
  color: black !important;
  padding-top: 10px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 8px;

  div {
    border-radius: 20px;
    padding: 8px;
  }

  div:hover {
    background-color: #efefef;
  }

  .selected {
    background-color: red;
  }
`;
