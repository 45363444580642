import styled from "styled-components";

export const ProfileSection = styled.section`
  .container {
    position: relative;
    padding: 0;
    width: 100%;
  }

  .avatar {
    width: 150px;
    height: auto;
    position: absolute;
    bottom: -15%;
    left: 8%;
  }

  h1 {
    margin-top: 100px;
    font-size: 50px;
    font-weight: 700;
  }

  h2 {
    color: gray;
    margin-bottom: 40px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    .avatar {
      width: 80px;
      bottom: -25%;
    }

    h1 {
      font-size: 24px;
      margin-top: 40px;
    }

    h2 {
      font-size: 16px;
    }
  }
`;

export const Banner = styled.img`
  border-radius: 50px;
  width: 100%;
  height: auto;
  max-width: 100%;

  @media (max-width: 768px) {
    border-radius: 20px;
  }
`;

export const ProfileContainer = styled.div`
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
