import styled from "styled-components";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router-dom";

export const StyledListItemButton = styled(ListItemButton)`
  display: flex !important;
  align-items: center;
  border-radius: 100px !important;
  color: #000 !important;
  margin: 12px 0 !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.3s !important;
  padding: 12px 15px !important;

  &:hover {
    background-color: #0029ff !important;
    color: #fff !important;
  }

  &.Mui-disabled {
    background-color: #9e9e9e !important;
    color: #fff !important;
    pointer-events: all !important;
  }

  svg {
    margin-right: 10px;
  }
`;

export const DrawerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  padding: 16px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const ButtonSection = styled.div`
  height: 100vh;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;
