import React from "react";
import Box from "@mui/material/Box";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { toast } from "react-toastify";
import {
  StyledListItemButton,
  DrawerListContainer,
  StyledLink,
  ButtonSection,
} from "./style";
import { Typography } from "@mui/material";

interface DrawerListProps {
  onClick: () => void;
}

const DrawerList: React.FC<DrawerListProps> = ({ onClick }) => {
  const notify = () => toast.info("Em breve mais novidades!");

  return (
    <Box sx={{ width: 300, p: 0 }} role="presentation" onClick={onClick}>
      <DrawerListContainer>
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold" }}
          gutterBottom
        >
          Mundo Tecler
        </Typography>
        <ButtonSection>
          <div>
            {/* Home */}
            <StyledLink to="/">
              <StyledListItemButton>
                <PublicOutlinedIcon />
                <span>Mundo Tecler</span>
              </StyledListItemButton>
            </StyledLink>
            {/* Meu RH */}
            <StyledLink to="/meurh">
              <StyledListItemButton>
                <DescriptionOutlinedIcon />
                <span>Meu RH</span>
              </StyledListItemButton>
            </StyledLink>
            {/* FAQ */}
            <StyledLink to="/faq">
              <StyledListItemButton>
                <ContactSupportOutlinedIcon />
                <span>FAQ</span>
              </StyledListItemButton>
            </StyledLink>
            {/* Perfil */}
            <StyledLink to="/profile">
              <StyledListItemButton>
                <PersonIcon />
                <span>Perfil</span>
              </StyledListItemButton>
            </StyledLink>
            {/* Botões Desativados */}
            <StyledListItemButton disabled onClick={notify}>
              <PhotoLibraryOutlinedIcon />
              <span>Registro de Eventos</span>
            </StyledListItemButton>
            <StyledListItemButton disabled onClick={notify}>
              <ReceiptLongOutlinedIcon />
              <span>Registro Fiscal</span>
            </StyledListItemButton>
            <StyledListItemButton disabled onClick={notify}>
              <AccessTimeIcon />
              <span>Registro de Atividades</span>
            </StyledListItemButton>
            <StyledListItemButton disabled onClick={notify}>
              <AccessTimeIcon />
              <span>Batida de Ponto</span>
            </StyledListItemButton>
          </div>
          {/* Sair */}
          <div>
            <StyledLink to="https://teclat.com.br/">
              <StyledListItemButton>
                <ExitToAppIcon />
                <span>Voltar para o site</span>
              </StyledListItemButton>
            </StyledLink>
          </div>
        </ButtonSection>
      </DrawerListContainer>
    </Box>
  );
};

export default DrawerList;
