import axios, { AxiosError } from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const url = baseUrl + "/user/";
interface UserData {
  email: string;
  name: string;
}

export const postUser = async (): Promise<string | void> => {
  try {
    // Obter dados do localStorage
    const dataFromLocalStorage = JSON.parse(localStorage.getItem("data") || "");

    // Verificar se 'dataFromLocalStorage' é válido
    if (
      !dataFromLocalStorage ||
      !dataFromLocalStorage.email ||
      !dataFromLocalStorage.name
    ) {
      throw new Error("Dados inválidos no localStorage");
    }

    // Montar objeto de dados do usuário
    const userData: UserData = {
      email: dataFromLocalStorage.email,
      name: dataFromLocalStorage.name,
    };

    // Enviar requisição POST
    const response = await axios.post(url, userData);

    console.log("Usuário cadastrado com sucesso:", response.data);
    return response.data;
  } catch (error) {
    // Tratar erro
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 400) {
        console.warn("Usuário já estava cadastrado!");
        return "Usuário já estava cadastrado!";
      } else {
        console.error("Erro ao cadastrar usuário:", axiosError.message);
        throw axiosError;
      }
    } else {
      console.error("Erro ao cadastrar usuário:", error);
      throw error;
    }
  }
};
