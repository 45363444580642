import React from 'react';
import { ResultList } from './style';

interface SearchResult {
  id: number;
  question: string;
}

interface SearchResultListProps {
  results: SearchResult[];
  onItemClick: (id: number) => void;
}

const SearchResultList: React.FC<SearchResultListProps> = ({ results, onItemClick }) => {
  const scrollToResult = (id: number) => {
    const targetDiv = document.getElementById(id.toString());
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleItemClick = (id: number) => {
    scrollToResult(id);
    onItemClick(id);
  };

  return (
    <ResultList>
      {results.map((result) => (
        <div
          key={result.id}
          onClick={() => handleItemClick(result.id)}
          style={{ cursor: 'pointer' }}
        >
          {result.question}
        </div>
      ))}
    </ResultList> 
  );
};

export default SearchResultList;
