import React from "react";
import Header from "../../Components/Header";
import FooterContent from "../../Components/Footer";
import SupportButton from "../../Components/Support";
import Benefits from "../../Components/Benefits/";
import Documents from "../../Components/Documents";
import { SectionRh } from "./style";
import Layout from "../../Components/Layout";
import { Typography } from "@mui/material";

function Rh() {
  return (
    <Layout>
      <SectionRh>
        <Typography variant="h1">Meu RH</Typography>
        <Benefits />
        <Documents />
      </SectionRh>
    </Layout>
  );
}

export default Rh;
