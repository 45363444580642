import styled from "styled-components";
import { Box } from "@mui/material";

export const SectionBenefits = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .banner-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 50%;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  .banner-wrapper:hover {
    transform: scale(1.1);
  }

  .banner-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BenefitModal = styled(Box)`
  position: fixed;
  width: 85%;
  max-width: 600px;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;

  .modal-body {
    padding: 50px;
  }
  @media (max-width: 768px) {
    max-width: 350px;
  }
`;

export const CustomImgModal = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 2 / 1;
  object-fit: cover;
  border-radius: 20px;
`;

export const StyledDescription = styled.p`
  color: #666;
  padding-bottom: 50px;
   width: 100%;
`;
