import styled from "styled-components";

export const ModalBox = styled.div`
  width: 50%;
  margin: auto;
  margin-top: 64px;
  padding: 16px;
  background-color: white;

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 48px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomModalBody = styled.div`
  overflow: auto;
  max-height: 500px;
  scrollbar-width: thin;
  scrollbar-color: #ffffff;

  ::-webkit-scrollbar {
    width: 10px;
  }
`;
export const NotificationHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;
export const NotificationContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  width: 95%;
  border-radius: 20px;
  border-left: 5px solid #05c846;
  margin-bottom: 20px;
  gap: 10px;
  padding: 20px 20px 0 20px;

  h1 {
    font-size: 20px;
  }
`;
