import styled from "styled-components";

export const SectionAnnouncements = styled.section`
  width: 100%;
  margin-top: 50px;

  .card {
    position: relative;
    margin-top: 40px;
  }

  .card-img {
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  .card-title {
    font-weight: bold;
  }

  .card-text {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
  }

  .card-description {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
  }

  .card-button {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    margin-top: 40px;

    .card {
      margin-top: 20px;
    }
  }

  h1 {
    font-size: 50px;
    font-weight: bold;
    color: #000;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 30px;
    }
  }
`;

export const DateConteiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

export const AnnouncementFlag = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: #0029ff;
  padding: 5px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
  }
`;
