import styled from "styled-components";
import { Container as MuiContainer } from "@mui/material";

export const ResponsiveContainer = styled(MuiContainer)`
  max-width: 100% !important;
  padding: 70px;

  /* Media queries para responsividade */
  @media (max-width: 1200px) {
    padding: 50px;
  }

  @media (max-width: 992px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;
