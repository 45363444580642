import React, { useState, useEffect } from "react";
import api from '../../Services/config';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";

interface Question {
  id: number;
  question: string;
  answer: string;
}

interface QuestionsProps {
  highlightedId: number | null;
  setHighlightedId: React.Dispatch<React.SetStateAction<number | null>>;
}

const Questions: React.FC<QuestionsProps> = ({
  highlightedId,
  setHighlightedId,
}) => {
  const [questions, setQuestions] = useState<Question[]>([]);

  const fetchQuestions = () => {
    api
      .get<Question[]>('/api/perguntas/')
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((error) => {
        console.error("Erro:", error);
      });
  };

  useEffect(() => {
    fetchQuestions();

    const interval = setInterval(() => {
      fetchQuestions();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const returnInitialColors = () => {
    setHighlightedId(null);
  };

  return (
    <>
      <Typography variant="h2">Dúvidas Frequentes</Typography>
      <div onClick={returnInitialColors}>
        {questions.map((question) => (
          <div key={question.id.toString()}>
            <Accordion
              id={question.id.toString()}
              style={{
                backgroundColor:
                  highlightedId === question.id ? "#afafaf" : "#F4F4F4",
                marginBottom: "20px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {question.question}
              </AccordionSummary>
              <AccordionDetails
                dangerouslySetInnerHTML={{ __html: question.answer }}
              />
            </Accordion>
          </div>
        ))}
      </div>
    </>
  );
};

export default Questions;
