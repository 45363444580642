import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Modal,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import api from "../../Services/config"
import CloseIcon from "@mui/icons-material/Close";
import {
  BenefitModal,
  SectionBenefits,
  CustomImgModal,
  StyledDescription,
} from "./style";

interface Benefit {
  id: number;
  banner: string;
  name: string;
  description: string;
  pin: boolean;
}

function Benefits() {
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit | null>(null);
  const [showModal, setShowModal] = useState(false);
  
  const fetchBenefits = async () => {
    try {
      const response = await api.get<Benefit[]>('/api/beneficios/');
      setBenefits(response.data);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  useEffect(() => {
    fetchBenefits();
    const interval = setInterval(() => {
      fetchBenefits();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const handleBannerClick = (benefit: Benefit) => {
    setSelectedBenefit(benefit);
    setShowModal(true);
  };

  return (
    <>
      <SectionBenefits>
        <Typography variant="h2">Benefícios</Typography>
        <Grid container spacing={3}>
          {benefits.map((benefit) => (
            <Grid item xs={12} md={4} key={benefit.id}>
              <Box
                className="banner-wrapper"
                onClick={() => handleBannerClick(benefit)}
              >
                <img src={benefit.banner} alt="banner" />
              </Box>
            </Grid>
          ))}
        </Grid>
      </SectionBenefits>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <BenefitModal>
          <IconButton
            onClick={() => setShowModal(false)}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Box p={4}>
            <Typography variant="h1">{selectedBenefit?.name || ""}</Typography>
            <StyledDescription
              dangerouslySetInnerHTML={{
                __html: selectedBenefit?.description || "",
              }}
            />
            <CustomImgModal
              className="img-fluid"
              src={selectedBenefit?.banner}
              alt="banner"
            />
          </Box>
        </BenefitModal>
      </Modal>
    </>
  );
}

export default Benefits;
