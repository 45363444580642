import styled from "styled-components";

// Contêiner para o avatar e o nome do usuário
export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 786px) {
    display: none;
  }
`;
