import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Logo from "../../Assets/logo.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Notifications from "../Notifications";
import Perfil from "../avatar";
import "react-toastify/dist/ReactToastify.css";
import DrawerList from "../DrawerList";
import {
  StyledHeader,
  MenuWrapper,
  LogoWrapper,
  MenuItens,
  StyledDrawer,
} from "./style";

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <StyledHeader>
      <MenuWrapper>
        <LogoWrapper>
          <Button onClick={toggleDrawer(true)}>
            <MenuIcon className="icon-menu" />
          </Button>
          <Link to="/">
            <img src={Logo} alt="logo Tecla T" />
          </Link>
        </LogoWrapper>
        <MenuItens>
          <Perfil />
          <Notifications />
        </MenuItens>
      </MenuWrapper>
      <StyledDrawer open={open} onClose={toggleDrawer(false)}>
        <DrawerList onClick={toggleDrawer(false)} />
      </StyledDrawer>
    </StyledHeader>
  );
};

export default Header;
