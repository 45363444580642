import styled from "styled-components";

// Container principal do banner
export const SectionBanner = styled.section`
  height: max-content;
  width: 100%;
  padding: 0 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
  }

  // Estilo do banner no topo
  .banner-top {
    max-width: 100%;
    width: 100%;
    height: 500px;
    border-radius: 50px;

    @media (max-width: 766px) {
      height: 200px;
    }
  }

  // Contêiner do título no topo
  .title-top {
    position: absolute;
    bottom: -50px;
    left: 250px;
    background: #00022f;
    width: 600px;
    height: 200px;
    border-radius: 25px;
    padding: 50px 0 50px 50px;
    display: flex;
    flex-direction: column;

    @media (max-width: 766px) {
      left: 20px;
      width: 250px;
      height: 100px;
      padding: 20px 30px;
    }
  }

  // Estilo do título (h1) dentro do contêiner do título
  h1 {
    color: #fff;
    text-align: left;
  }

  // Estilo do parágrafo (p) dentro do contêiner do título
  p {
    color: #fff;
    margin-bottom: 0;

    @media (max-width: 766px) {
      font-size: 11px;
    }
  }
`;

// Container principal do post
export const ContentPost = styled.section`
  height: max-content;
  width: 100%;
  padding: 200px 250px 100px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1150px) {
    padding: 100px 70px 20px;
  }

  @media (max-width: 600px) {
    padding: 100px 20px 20px;
  }

  // Estilo das imagens dentro do contêiner do post
  img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
  }
`;

// Estilo do título do post
export const TitlePost = styled.h1`
  font-size: 40px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin-bottom: 5px;

  @media (max-width: 766px) {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

// Estilo do texto do post
export const TextPost = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-align: justify;

  @media (max-width: 766px) {
    font-size: 15px;
  }
`;
