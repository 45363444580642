import styled from "styled-components";

export const ContentBanner = styled.section`
  .carousel-inner {
    height: 500px;
    display: flex;
    align-items: center;
    border-radius: 30px;
  }

  .carousel-item img {
    max-height: 100%;
    width: auto;
    object-fit: contain;
    border-radius: 20px;
  }

  .legend-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }

  .legend-banner div {
    top: 42%;
  }

  h1 {
    font-size: 42px;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
  }

  p {
    font-size: 20px;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
  }

  @media (max-width: 768px) {
    .carousel-inner {
      height: fit-content;
    }

    .legend-banner {
      height: auto;
      top: auto;
      padding: 10px;
    }

    .legend-banner div {
      top: auto;
      margin-bottom: 25px;
    }

    h1 {
      font-size: 24px;
      text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
    }

    p {
      font-size: 16px;
      text-shadow: 2px 2px 4px rgb(0 0 0 / 75%);
    }
  }
`;
