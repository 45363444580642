import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Section,
  ContentLogin,
  Header,
  Image,
  ImageBack,
  ImageBack2,
} from "./style";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import axios from "axios";
import GoogleIcon from "@mui/icons-material/Google";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Logo from "../../Assets/logo2.png";
import Back from "../../Assets/de.webp";
import Back2 from "../../Assets/de3.webp";
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";



// Tipagem para as props do componente Login
interface LoginProps {
  onGoogleLoginSuccess: (profile: any) => void;
  onGoogleLoginError: () => void;
  setProfile: (profile: any) => void;
}

// Tipagem para o estado user
interface User {
  access_token: string;
}

const Login: React.FC<LoginProps> = ({
  onGoogleLoginSuccess,
  onGoogleLoginError,
  setProfile,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse: TokenResponse) => {
      setUser({ access_token: tokenResponse.access_token });
      localStorage.setItem("isAuthenticated", "true");
    },
    onError: (error) => {
      console.log("Login Failed:", error);
      onGoogleLoginError();
    },
  });

  useEffect(() => {
    if (user) {
      // Obtenha informações do usuário a partir do token do Google
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("data", JSON.stringify(res.data));
          setProfile(res.data);
          onGoogleLoginSuccess(res.data);
          

          // Obtenha o email armazenado no localStorage
          const email = res.data.email;

          // Envie o email para o backend
          axios
            .post("http://localhost:8000/api/google-login/", { email })
            .then(() => {
              console.log("User authenticated");
            })
            .catch((error) => {
              console.log("Authentication error", error);
            });
            navigate("/");
        })
        .catch((err) => console.log(err));
    }
  },  [user, setProfile, onGoogleLoginSuccess, navigate]);

  return (
    <>
      {localStorage.getItem("isAuthenticated") === "true" ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>


      ) : (
        <Section>
          <ImageBack src={Back} />
          <ImageBack2 src={Back2} />

          <Header>
            <Link to="https://teclat.com.br/">
              <ExitToAppRoundedIcon className="icon-exit" />
            </Link>
          </Header>
          <Container maxWidth="sm">
            <ContentLogin>
              <Image src={Logo} alt="Logo" />
              <Button
                variant="contained"
                color="primary"
                className="google-button"
                startIcon={<GoogleIcon />}
                onClick={() => login()}
              >
                Entrar com o Google
              </Button>
            </ContentLogin>
          </Container>
        </Section>
      )}
    </>
  );
};

export default Login;
