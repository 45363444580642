import styled from "styled-components";

export const FooterContent = styled.footer`
  height: max-content;
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  background: #00022f;
  margin-top: 50px;

  img {
    height: 100px;
    width: auto;
  }

  @media (max-width: 768px) {
    padding: 30px;

    img {
      height: 80px;
      width: auto;
      margin: 0;
    }
  }
`;
