import styled from "styled-components";

export const StyledSearchBar = styled.div`
  background-color: white;
  width: 100%;
  margin-top: 30px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  color: #999999 !important;

  input {
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;
