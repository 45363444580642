import styled from "styled-components";

export const CommentSection = styled.section`
  margin: 50px 0;
  @media (max-width: 766px) {
    margin: 20px 0;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 20px;
  border: 2px solid #d2d2d2;
  width: 100%;
  border-radius: 50px;
  padding: 5px 10px 5px ;
`;

export const ResponseContainer = styled.div`
  margin-left: 30px;
  @media (max-width: 766px) {
    margin-left: 20px;
  }
`;
export const TextContainer = styled.div`
  margin-left: 50px;
`;

export const InputField = styled.input`
  flex: 1;
  padding: 10px;
  background-color: transparent;
  margin-right: 10px;
  border: none;
  outline: none;
`;
export const InputAvatar = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const AnswerField = styled.input`
  flex: 1;
  padding: 10px;
  background-color: transparent;
  margin-right: 10px;
  border: none;
  outline: none;

  @media (max-width: 766px) {
    margin-right: 0;
    padding: 10px 0;
  }
`;

export const SendButton = styled.button`
  padding: 10px;
  border: none;
  color: #d2d2d2;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: darkgray;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const CommentList = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column-reverse;
`;

export const CommentItem = styled.div`
  padding: 10px;
  margin: 5px;
`;

export const CommentAuthor = styled.span`
  font-weight: bold;
`;
export const DateContainer = styled.div`
  justify-content: center;
  align-items: center;
`;

export const CommentCreatedAt = styled.span`
  font-weight: 200;
  font-size: 13px;
  color: #777;
  @media (max-width: 766px) {
    padding-right: 10px;  /* Ajuste para dispositivos menores */
  }
`;

export const CommentText = styled.p`
  margin-top: 5px;
`;

export const CommentResponseButton = styled.button`
  border: none;
  gap: 5px;
  background-color: transparent;
  color: #444444;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  padding: 0 !important;

  &:hover {
    color: #777;
    text-decoration: underline;
  }
`;

export const CenteredProgress = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap:10px;
  
  & > .MuiAvatar-root {
  }
`;
