import React, { useEffect, useState } from "react";
import axios from "axios";
import profileBanner from "../../Assets/profileBanner.png";
import { Avatar, Container, Typography } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupsIcon from "@mui/icons-material/Groups";
import { ProfileSection, Box, ProfileContainer, Banner } from "./style";
import Layout from "../../Components/Layout";

interface UserData {
  name: string;
  email: string;
  picture: string;
}

interface UserProfile {
  position: string;
  squad: string;
}

const Profile: React.FC = () => {
  const storedData = localStorage.getItem("data");
  const data: UserData | null = storedData ? JSON.parse(storedData) : null;

  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (data && data.email) {
        try {
          const response = await axios.get<UserProfile>(
            `http://localhost:8000/api/profile?email=${data.email}`
          );
          setUserProfile(response.data);
        } catch (error) {
          console.error("Erro ao buscar dados do usuário:", error);
        }
      }
    };

    fetchUserProfile();
  }, [data]);

  let name = "";
  let picture = "";

  if (data) {
    const nameParts = data.name.split(" ");
    name = `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
    picture = data.picture;
  }

  return (
    <>
      <Layout>
      ''
        <ProfileSection>
          <div className="container">
            <Banner src={profileBanner} />
            <Avatar alt="profile image" src={picture} className="avatar" />
          </div>
          <ProfileContainer>
            <Typography variant="h1" component="h1" gutterBottom>
              {name}
            </Typography>

            <Typography variant="h6" component="h2">
              {userProfile?.position || "Posição não encontrada"}
            </Typography>

            <>
              <Box>
                <AlternateEmailIcon />
                <p>{data?.email}</p>{" "}
              </Box>
              <Box>
                <LocationOnIcon />
                <p>Hapvida</p>
              </Box>
              <Box>
                <GroupsIcon />
                <p>{userProfile?.squad || "Times não encontrados"}</p>
              </Box>
            </>
          </ProfileContainer>
        </ProfileSection>
      </Layout>
    </>
  );
};

export default Profile;
