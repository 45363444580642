import styled from 'styled-components';

export const SectionDocuments = styled.section`
display: flex;
justify-content: center;
flex-direction: column;
`   

export const ButtonView = styled.a`
padding: 15px;
background-color: #0029FF;
display: inline-flex;
border-radius: 10px;
cursor: pointer;
color: #fff;
transition: .3s;
text-decoration: none;
margin-top: 10px;
&:hover{
    transform: scale(1.1);
}

`   