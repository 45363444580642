import React, { useState, useEffect, ChangeEvent } from "react";
import api from "../../Services/config"; // Importa a instância configurada
import {
  CommentSection,
  InputContainer,
  CommentList,
  CommentItem,
  SendButton,
  InputField,
  CommentAuthor,
  CommentText,
  ResponseContainer,
  CommentResponseButton,
  AnswerField,
  AvatarContainer,
  TextContainer,
  CenteredProgress,
  CommentCreatedAt,
  InputAvatar,
  DateContainer
} from "./style";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { emit } from "process";

interface Comment {
  id: number;
  text: string;
  user: string;
  responses: Comment[];
  createdAt: string;
  fullName: string;
}

interface CommentsProps {
  announcementId: number;
}

const Comments: React.FC<CommentsProps> = ({ announcementId }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState<string>("");
  const [newResponse, setNewResponse] = useState<{
    parentId: number | null;
    text: string;
  }>({ parentId: null, text: "" });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewComment(e.target.value);
  };

  const handleResponseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewResponse({ ...newResponse, text: e.target.value });
  };

  const fetchComments = async () => {
    try {
      setLoading(true);
      const response = await api.get<Comment[]>('/api/comentario/', {
        params: { announcement_id: announcementId },
      });
      setComments(response.data);
    } catch (error) {
      console.error("Erro:", error);
      setError("Erro ao buscar comentários");
    } finally {
      setLoading(false);
    }
  };

  const formatUserName = (fullName: string) => {
    const names = fullName.split(" ");
    if (names.length > 1) {
      return `${names[0]} ${names[names.length - 1]}`;
    }
    return fullName;
  };
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
    const intervals: { [key: string]: number } = {
      ano: 365 * 24 * 60 * 60,
      mês: 30 * 24 * 60 * 60,
      dia: 24 * 60 * 60,
      hora: 60 * 60,
      minuto: 60,
      segundo: 1,
    };
  
    for (const key in intervals) {
      const intervalValue = intervals[key];
      const amount = Math.floor(diffInSeconds / intervalValue);
  
      if (amount >= 1) {
        if (amount === 1) {
          return `há ${amount} ${key}`;
        } else {
          const pluralKey = key === "mês" ? "meses" : `${key}s`;
          return `há ${amount} ${pluralKey}`;
        }
      }
    }
  
    return "agora mesmo";
  };
  

  const handleCommentSubmit = async () => {
    if (newComment.trim() !== "") {
      const storedData = localStorage.getItem("data");
      const data = storedData ? JSON.parse(storedData) : null;
      const userEmail = data?.email || "bernardo@teclat.com.br";
      setIsSubmitting(true);
      try {
        await api.post('/api/comentario/', {
          text: newComment,
          user: userEmail,
          announcement: announcementId,
        });
        setNewComment("");
        fetchComments();
      } catch (error) {
        console.error("Erro ao enviar comentário:", error);
        setError("Erro ao enviar comentário");
      } finally {
        setIsSubmitting(false);
      }
    }
};

const handleResponseSubmit = async (parentId: number) => {
  if (newResponse.text.trim() !== "") {
      const storedData = localStorage.getItem("data");
      const data = storedData ? JSON.parse(storedData) : null;
      const userEmail = data?.email || "bernardo@teclat.com.br";
      setIsSubmitting(true);
      try {
          await api.post('/api/resposta/', {
          text: newResponse.text,
          user: userEmail,
          commentary: parentId,
        });
        setNewResponse({ parentId: null, text: "" });
        fetchComments();
      } catch (error) {
          console.error("Erro ao enviar resposta:", error);
          setError("Erro ao enviar resposta");
      } finally {
          setIsSubmitting(false);
      }

  }
};
  useEffect(() => {
    fetchComments();
  }, [announcementId]);

  return (
    <CommentSection>
      <InputContainer>
        <InputField
          type="text"
          value={newComment}
          onChange={handleChange}
          placeholder="Adicionar comentário"
        />
        <SendButton onClick={handleCommentSubmit} disabled={isSubmitting}>
          <SendIcon />
        </SendButton>
      </InputContainer>
      {loading && (
        <CenteredProgress>
          <CircularProgress />
        </CenteredProgress>
      )}
      {error && <p>{error}</p>}
      <CommentList>
        {comments.map((comment, index) => (
          <CommentItem key={`${comment.id}-${index}`}>
            <AvatarContainer>
              <Avatar alt="user avatar" />
              <CommentAuthor>{formatUserName(comment.user)}</CommentAuthor>
              <DateContainer>
                <CommentCreatedAt>
                    {formatDate(comment.createdAt)}
                </CommentCreatedAt>
              </DateContainer>
            </AvatarContainer>
            <TextContainer>
              <CommentText>{comment.text}</CommentText>
              <CommentResponseButton
                onClick={() =>
                  setNewResponse({ parentId: comment.id, text: "" })
                }
              >
                <QuestionAnswerOutlinedIcon/>
                Responder
              </CommentResponseButton>
            </TextContainer>
            <ResponseContainer>
              {newResponse.parentId === comment.id && (
                <InputContainer>
                  <AnswerField
                    type="text"
                    value={newResponse.text}
                    onChange={handleResponseChange}
                    placeholder="Adicionar resposta"
                  />
                  <SendButton
                    onClick={() => handleResponseSubmit(comment.id)}
                    disabled={isSubmitting}
                  >
                    <SendIcon />
                  </SendButton>
                </InputContainer>
              )}

              {comment.responses && comment.responses.length > 0 && (
                <CommentList>
                  {comment.responses.map((response) => (
                    <CommentItem key={response.id}>
                      <AvatarContainer>
                        <Avatar
                          alt="user avatar"
                          src="/static/images/avatar/1.jpg"
                        />
                        <CommentAuthor>
                          {formatUserName(comment.user)}
                        </CommentAuthor>
                        <CommentCreatedAt>
                          {formatDate(response.createdAt)}
                        </CommentCreatedAt>
                      </AvatarContainer>
                      <TextContainer>
                        <CommentText>{response.text}</CommentText>
                      </TextContainer>
                    </CommentItem>
                  ))}
                </CommentList>
              )}
            </ResponseContainer>
          </CommentItem>
        ))}
      </CommentList>
    </CommentSection>
  );
};

export default Comments;
