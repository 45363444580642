import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../Services/config"
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { SectionAnnouncements, AnnouncementFlag, DateConteiner } from "./style";

interface Announcement {
  id: number;
  status: string;
  announcement_type: string;
  Banner: string;
  title: string;
  formatted_date: string;
  description: string;
}

const Announcements: React.FC = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await api.get<Announcement[]>('/api/comunicados/');
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Erro:", error);
      }
    };

    fetchAnnouncements();
    const interval = setInterval(fetchAnnouncements, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <SectionAnnouncements>
      <Typography variant="h1">Eventos e Comunicados</Typography>
      <Grid container spacing={4}>
        {announcements
          .filter((announcement) => announcement.status === "ATIVO")
          .map((announcement) => (
            <Grid item xs={12} md={4} key={announcement.id}>
              <Card className="card" sx={{ borderRadius: "10px" }}>
                <AnnouncementFlag>
                  <Typography variant="h6">
                    {announcement.announcement_type}
                  </Typography>
                </AnnouncementFlag>
                <CardMedia
                  component="img"
                  className="card-img"
                  image={announcement.Banner}
                  alt={announcement.title}
                />
                <CardContent>
                  {/* Título */}
                  <Typography variant="h5" className="card-title">
                    {announcement.title}
                  </Typography>
                  {/* Data */}
                  <DateConteiner>
                    <CalendarMonthOutlinedIcon />
                    <Typography variant="body1" className="card-text">
                      {announcement.formatted_date}
                    </Typography>
                  </DateConteiner>
                  {/* Descrição */}
                  <Typography variant="body1" className="card-description">
                    {announcement.description}
                  </Typography>
                  {/* Link para leitura completa */}
                  <Link to={`/post/${announcement.id}`}>
                    <Button variant="contained" className="card-button">
                      Ver Mais
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </SectionAnnouncements>
  );
};

export default Announcements;
