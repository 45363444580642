import React, { useState, ChangeEvent } from "react";
import { StyledSearchBar } from "./style";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../Services/config"; // Importa a instância configurada do Axios

interface SearchBarProps {
  setResults: React.Dispatch<React.SetStateAction<any[]>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ setResults }) => {
  const [input, setInput] = useState<string>("");

  const fetchData = (value: string) => {
    api.get("/api/perguntas/")
      .then((response) => {
        const results = response.data.filter((question: { question: string }) => {
          return (
            value &&
            question &&
            question.question &&
            question.question.toLowerCase().includes(value.toLowerCase())
          );
        });
        setResults(results);
      })
      .catch((error) => {
        console.error("Erro ao buscar perguntas:", error);
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInput(value);
    fetchData(value);
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledSearchBar>
        <input
          type="text"
          placeholder="Pesquise aqui..."
          value={input}
          onChange={handleChange}
        />
        <SearchIcon />
      </StyledSearchBar>
    </div>
  );
};

export default SearchBar;
