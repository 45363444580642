import styled from "styled-components";

export const SectionFaq = styled.section`
  width: 100%;
`;

export const Gradient = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(
    180deg,
    rgba(0, 237, 38, 1) 0%,
    rgba(0, 9, 47, 1) 98%
  );
  border-radius: 20px;
  margin-bottom: 80px;

  h1,
  h2 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    padding: 20px;
    height: 300px;
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 16px;
    }
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FaqContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #00022f;
  border-radius: 20px;
  margin-bottom: 80px;
  text-align: center;
  margin-top: 100px;

  h1,
  h2 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    padding: 20px;
    margin-top: 0;

    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 16px;
    }
  }
`;

export const ContactButton = styled.a`
  padding: 15px 50px;
  background-color: white;
  color: black !important;
  font-weight: 500 !important;
  display: inline-flex;
  gap: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  margin-top: 30px;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    gap: 5;
    padding: 10px 30px;
  }
`;
