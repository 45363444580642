import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/Home/App";
import Login from "./Pages/Login/index";
import SinglePost from "./Pages/SinglePost/index";
import { GoogleOAuthProvider, CredentialResponse } from "@react-oauth/google";
import "./index.css";
import Rh from "./Pages/Rh";
import Faq from "./Pages/Faq";
import Profile from "./Pages/Profile";
import { ToastContainer } from "react-toastify";
import theme from "./Themes/theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Tipagem para as props do Login component
interface LoginProps {
  onGoogleLoginSuccess: (credentialResponse: CredentialResponse) => void;
  onGoogleLoginError: () => void;
  isAuthenticated: boolean;
  setProfile: (profile: any) => void;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    localStorage.getItem("isAuthenticated") === "true"
  );
  const [profile, setProfile] = useState<any>(null);
  const ClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

  const handleGoogleLoginSuccess = (credentialResponse: CredentialResponse) => {
    console.log(credentialResponse);

    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");
  };

  const handleGoogleLoginError = () => {
    console.log("Login Failed");
  };

  return (
    <GoogleOAuthProvider clientId={ClientId}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Router>
          <Routes>

            <Route
              path="/login"
              element={
                <Login
                  onGoogleLoginSuccess={handleGoogleLoginSuccess}
                  onGoogleLoginError={handleGoogleLoginError}
                  setProfile={setProfile}
                />
              }
            />
            <Route
                path="/"
                element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
              />
            <Route
              path="/meurh"
              element={isAuthenticated ? <Rh /> : <Navigate to="/login" />}
            />
            <Route
              path="/faq"
              element={isAuthenticated ? <Faq /> : <Navigate to="/login" />}
            />
            <Route
              path="/profile"
              element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
            />
            <Route
              path="/post/:id"
              element={
                isAuthenticated ? <SinglePost /> : <Navigate to="/login" />
              }
            />


          </Routes>
          <ToastContainer position="top-center" pauseOnHover />
        </Router>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
