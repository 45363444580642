import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0029ff",
    },
    secondary: {
      main: "#00022f",
    },
  },
  typography: {
    h1: {
      fontSize: "3.25rem",
      fontWeight: 700,
      color: "#000",
      marginBottom: "1.5rem",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 600,
      color: "#5A5A5A",
      marginBottom: "1rem",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },
});

export default theme;
