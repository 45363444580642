import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

export const StyledHeader = styled.header`
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #00000024;
  z-index: 999;
`;

export const MenuWrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  img {
    height: 50px;
    width: auto;
  }

  button {
    background: transparent;
    border: none;
    border-radius: 100px;

    &:hover {
      background: #00022f;
      border-radius: 200px;
    }
  }

  svg {
    font-size: 30px;
    color: #000;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #fff;
    }
  }
`;

export const MenuItens = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    width: 300px;
  }
`;
