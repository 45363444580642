import React, { useState, useEffect } from "react";
import api from "../../Services/config"
import {
  Button,
  Badge,
  Modal,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  CustomModalBody,
  ModalBox,
  ModalHeader,
  NotificationContent,
  NotificationHeader,
} from "./style";

interface Notification {
  id: number;
  title: string;
  message: string;
}

interface PopupProps {
  style?: React.CSSProperties;
}

const Notifications: React.FC<PopupProps> = ({ style = {} }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<Notification[] | null>(null);
  const baseUrl = process.env.REACT_APP_BASE_URL || "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get<Notification[]>(
          '/api/notification/'
        );
        setData(response.data);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    };

    fetchData();
  }, [baseUrl]);

  const hideNotifications = (id: number) => {
    if (data) {
      setData(data.filter((item) => item.id !== id));
    }
  };

  return (
    <>
      <IconButton onClick={() => setShow(true)} sx={{ position: "relative" }}>
        <Badge badgeContent={data ? data.length : 0} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Modal open={show} onClose={() => setShow(false)}>
        <ModalBox>
          <ModalHeader>
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Notificações
            </Typography>
            <IconButton onClick={() => setShow(false)}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <CustomModalBody>
            {data ? (
              data.length === 0 ? (
                <Typography variant="h6">Você não tem notificações</Typography>
              ) : (
                data.map((item) => (
                  <Container key={item.id}>
                    <NotificationHeader>
                      <InfoIcon />
                      <NotificationContent>
                        <Typography variant="h6">{item.title}</Typography>
                        <IconButton onClick={() => hideNotifications(item.id)}>
                          <CloseIcon />
                        </IconButton>
                      </NotificationContent>
                    </NotificationHeader>
                    <Typography
                      sx={{ ml: 2 }}
                      dangerouslySetInnerHTML={{ __html: item.message }}
                    />
                  </Container>
                ))
              )
            ) : (
              <CircularProgress />
            )}
          </CustomModalBody>
        </ModalBox>
      </Modal>
    </>
  );
};

export default Notifications;
