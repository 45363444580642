import React from 'react';
import { ButtonHelp } from './style';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function SupportButton() {
  return (
    <>
      <ButtonHelp href='https://wa.me/5585996500038?text=Departamento+Pessoal' target='_blank'>
          <HelpOutlineIcon></HelpOutlineIcon>
      </ButtonHelp>
    </>
  );
}

export default SupportButton;
