import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { SectionBanner, ContentPost, TextPost, TitlePost } from "./style";
import Comments from "../../Components/Comments";
import Layout from "../../Components/Layout";

interface Comunicado {
  Banner: string;
  title: string;
  formatted_date: string;
  message: string;
}
const SinglePost: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [comunicado, setComunicado] = useState<Comunicado>({
    Banner: "",
    title: "",
    formatted_date: "",
    message: "",
  });
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (id) {
      const fetchComunicado = async () => {
        try {
          const response = await axios.get<Comunicado>(
            `${baseUrl}/api/comunicados/${id}/`
          );
          setComunicado(response.data);
        } catch (error) {
          console.error("Erro:", error);
        }
      };

      fetchComunicado();
    }
  }, [baseUrl, id]);

  return (
    <Layout>
      <SectionBanner>
        <img className="banner-top" src={comunicado.Banner} alt="Banner"></img>
        <div className="title-top">
          <TitlePost>{comunicado.title}</TitlePost>
          <p>{comunicado.formatted_date}</p>
        </div>
      </SectionBanner>
      <ContentPost>
        <TextPost dangerouslySetInnerHTML={{ __html: comunicado.message }} />
        {id && <Comments announcementId={parseInt(id)} />}
      </ContentPost>
    </Layout>
  );
};

export default SinglePost;
