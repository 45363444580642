// src/api.ts
import axios, { AxiosInstance } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Defina a baseURL aqui
  headers: {
    'Content-Type': 'application/json',
  },
  // Outras configurações padrão podem ser adicionadas aqui
});

export default api;
