import React, { useState, useEffect } from "react";
import BannerCarousel from "../../Components/Banner";
import Notices from "../../Components/Announcements";
import WelcomeModal from "../../Components/WelcomeModal";
import { postUser } from "../../Services/postUser";
import Layout from "../../Components/Layout";
import { BannerSection } from "./style";

const App = () => {
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    // Verificar se o usuário já visitou o site antes
    const hasVisitedBefore = localStorage.getItem("hasVisited");
    // Se o usuário nunca visitou antes, exibe o modal de boas-vindas
    if (!hasVisitedBefore) {
      setShowWelcomeModal(true);
      postUser()
        .then(() => {
          // Registra no armazenamento local que o usuário já visitou
          localStorage.setItem("hasVisited", "true");
        })
        .catch((error) => {
          console.error("Erro ao registrar visita do usuário:", error);
        });
    }
  }, []);

  const closeModal = () => {
    setShowWelcomeModal(false);
  };

  return (
    <>
      {showWelcomeModal && <WelcomeModal onClose={closeModal} />}
      <Layout>
        <BannerSection>
          <BannerCarousel />
        </BannerSection>
        <Notices />
      </Layout>
    </>
  );
};

export default App;
