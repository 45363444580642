import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ContentBanner } from "./style";
import api from "../../Services/config"
import { Link } from "react-router-dom";
import { Typography, CircularProgress, Skeleton } from "@mui/material";

interface Announcement {
  id: number;
  Banner: string;
  title: string;
  description: string;
  pin: boolean;
}

function BannerCarousel() {
  const [announcement, setAnnouncements] = useState<Announcement[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchAnnouncement = () => {
    api
      .get<Announcement[]>("/api/comunicados/")
      .then((response) => {
        setAnnouncements(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Erro:", error);
      });
  };

  useEffect(() => {
    fetchAnnouncement();
    const interval = setInterval(() => {
      fetchAnnouncement();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const announcements = announcement.filter(
    (announcement) => announcement.pin === true
  );
  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: "30px",
          height: {
            xs: 300,
            sm: 300,
            lg: 500
          }
        }}
      />
    )
  } 


  return (
    <ContentBanner>
      <Carousel>
        {announcements.map((announcement) => (
          <Carousel.Item key={announcement.id}>
            <Link to={`/post/${announcement.id}`}>
              <img
                className="d-block w-100"
                src={announcement.Banner}
                alt="Banner"
              />
              <div className="legend-banner">
                <Carousel.Caption>
                  <h1>{announcement.title}</h1>
                  <p>{announcement.description}</p>
                </Carousel.Caption>
              </div>
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
    </ContentBanner>
  );
}

export default BannerCarousel;